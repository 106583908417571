<template>
  <div class="send-failed">
    <div class="part border-radius-6 box-shadow-light-grey padding-20">
      <div class="flex-between operation padding-bottom-20">
        <div>
          <div>
            <el-select
              class="margin-right-twentyFour"
              v-model="currentOfficial"
              placeholder="请选择公众号"
              @change="handleOfficialChange"
              @clear="handleClear"
              style="width: 140px"
              filterable
              clearable
            >
              <el-option
                v-for="item in channelList"
                :key="item.id"
                :label="item.channelName"
                :value="item.id"
              >
                <span style="float: left">{{ item.channelName }}</span>
                <span style="float: right; margin-left: 15px; font-size: 13px"
                  >{{ item.platformStr }} - {{ item.uname }}</span
                >
              </el-option>
            </el-select>
            <el-select
              class="margin-right-twentyFour"
              v-model="type"
              placeholder="请选择消息类型"
              @change="handleAccountTypeChange"
              @clear="handleTypeClear"
              clearable
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="status"
              class="margin-right-twentyFour"
              placeholder="请选择确认状态"
              @change="handleStatusChange"
              @clear="handleStatusClear"
              clearable
            >
              <el-option
                v-for="item in statusList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-date-picker
              class="margin-right-twentyFour"
              v-model="chooseData"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
              :default-time="['00:00:00', '23:59:59']"
              @change="handleDataPickerChange"
            >
            </el-date-picker>
            <el-button @click="getList">刷新数据</el-button>
            <el-button
              :disabled="!selectData.length"
              type="success"
              @click="handleChangeAll"
              >批量修改</el-button
            >
            <el-button
              class="custom-button-margin-left"
              @click="resetData"
              type="info"
              >重置</el-button
            >
          </div>
        </div>
      </div>
      <el-table
        v-loading="loading"
        :data="tableData"
        height="67vh"
        style="width: 100%"
        @row-click="rowClick"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="relationId" width="80" label="关联ID">
        </el-table-column>
        <el-table-column label="消息类型" width="100">
          <template slot-scope="scope">
            <el-tag :type="getTagType(scope.row)">{{
              scope.row.type | formatType
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="失败信息" width="500">
          <el-table-column label="前端信息">
            <template slot-scope="scope">
              <el-popover placement="top" width="500" trigger="hover">
                <div>
                  {{ scope.row.frontMessage }}
                </div>
                <div class="line-overflow highlight-blue" slot="reference">
                  {{ scope.row.frontMessage }}
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="后端信息" width="400">
            <template slot-scope="scope">
              <el-popover placement="top" width="500" trigger="hover">
                <div>
                  {{ scope.row.errorMessage }}
                </div>
                <div class="line-overflow" slot="reference">
                  {{ scope.row.errorMessage }}
                </div>
              </el-popover>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="createdTime" label="创建时间" width="200">
        </el-table-column>
        <el-table-column prop="action" width="150" label="操作">
          <template slot-scope="scope">
            <el-button
              class="button-small"
              v-if="scope.row.status === 0"
              type="primary"
              @click.stop="handleComfirm(scope.row)"
              style="background: #579bf5; border-color: #579bf5"
              >确认</el-button
            >
            <span v-else style="color: #00bf8a">已确认</span>
            <el-button
              class="button-small"
              type="primary"
              v-if="scope.row.status === 0"
              @click.stop="handleResend(scope.row)"
              style="
                background: #579bf5;
                border-color: #579bf5;
                margin-left: 10px;
              "
              >重发</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { changeTipsStatus, getTipItemList, resendMessage } from "@/api/service";
import { mapGetters, mapMutations } from "vuex";
import { messagePlatformList } from "@/assets/js/options"; //客服消息支持平台
export default {
  name: "sendFailed",
  // props: ["accountChannelId"],
  data() {
    return {
      currentOfficial: null,
      accountType: 1,
      tableData: [],
      pageSize: 10,
      page: 1,
      total: 0,
      startDate: null, // 查询开始时间
      endDate: null, // 查询结束时间
      type: null, // 1 客服消息 2标签群发 3 小时发送
      status: null, // 0 未确认 1 已确认
      selected: [],
      loading: false,
      chooseData: null,
      messagePlatformList,
      typeList: [
        {
          id: 1,
          name: "客服消息",
        },
        {
          id: 2,
          name: "标签群发",
        },
        {
          id: 3,
          name: "小时群发",
        },
      ],
      statusList: [
        {
          id: 0,
          name: "未确认",
        },
        {
          id: 1,
          name: "已确认",
        },
      ],
      selectData: [],
    };
  },
  filters: {
    formatType(val) {
      if (val === 1) return "客服消息";
      if (val === 2) return "标签群发";
      if (val === 3) return "小时发送";
    },
  },
  beforeRouteEnter(to, form, next) {
    next((vm) => {
      const { accountChannelId } = vm.$route.params;
      vm.currentOfficial = accountChannelId;
      vm.getList();
    });
  },
  computed: {
    channelList() {
      const { officialList } = this;
      return officialList.filter(
        (item) =>
          messagePlatformList.includes(item.platform) && item.status === 1
      );
    },
    ...mapGetters(["officialList"]),
  },
  watch: {
    currentOfficial: {
      handler(newV) {
        if (newV) {
          this.getList();
        }
      },
    },
  },
  methods: {
    handleResend(data) {
      resendMessage(data.id).then((res) => {
        this.$message.success("重发成功");
        this.handlePageChange();
      });
    },
    handleChangeAll() {
      let ids = this.selectData.map((item) => {
        return item.id;
      });
      resendMessage(ids).then((res) => {
        this.$message.success("重发成功");
        this.handlePageChange();
      });
    },
    ...mapMutations(["setIsConfirmFailedInfo"]),
    getList() {
      this.loading = true;
      getTipItemList({
        page: this.page,
        pageSize: this.pageSize,
        startDate: this.startDate,
        endDate: this.endDate,
        type: this.type,
        status: this.status,
        accountChannelId: this.currentOfficial,
      })
        .then((res) => {
          this.tableData = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePageChange(page = this.page) {
      this.page = page;
      this.getList();
    },
    handleComfirm(row) {
      const { id } = row;
      this.$confirm("是否确认", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      }).then((res) => {
        changeTipsStatus(id).then((res) => {
          this.$message.success("确认成功");
          this.setIsConfirmFailedInfo(true);
          this.getList();
        });
      });
    },
    getTagType(row) {
      if (row.type === 1) return "";
      if (row.type === 2) return "danger";
      if (row.type === 3) return "warning";
    },
    rowClick(row, column) {
      if (!row.accountChannelId) return;
      const { relationId, type, accountChannelId } = row;
      sessionStorage.setItem(
        "messageAccount",
        JSON.stringify(accountChannelId)
      );
      this.$router.push({
        name: type === 1 ? "Service" : type === 2 ? "Tag" : "Hour",
        params: {
          relationId,
          accountChannelId,
        },
      });
    },
    handleOfficialChange(select) {
      sessionStorage.setItem("messageAccount", JSON.stringify(select));
    },
    handleClear() {
      this.currentOfficial = null;
      this.getList();
    },
    handleAccountTypeChange(type) {
      this.type = type;
      this.getList();
    },
    handleTypeClear() {
      this.type = null;
      this.getList();
    },
    handleDataPickerChange(val) {
      if (val) {
        this.startDate = val[0];
        this.endDate = val[1];
      }
      this.getList();
    },
    handleStatusChange(val) {
      this.status = val;
      this.getList();
    },
    handleStatusClear() {
      this.status = null;
      this.getList();
    },
    resetData() {
      this.startDate = null;
      this.endDate = null;
      this.type = null;
      this.status = null;
      this.chooseData = null;
      this.currentOfficial = null;
      this.getList();
    },
    handleSelectionChange(data) {
      this.selectData = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.highlight-blue {
  color: #579bf5;
  cursor: pointer;
}
/deep/ .el-table {
  border: none;
  thead {
    tr {
      th {
        border: none;
      }
    }
  }
  td {
    // border: none;
  }
}
.send-failed {
  .operation {
    background: #fff;
  }
  .part {
    box-sizing: border-box;
    background: #fff;
    height: calc(100vh - 106px);
  }
  /deep/ .el-drawer {
    /deep/ .el-drawer__header {
      text-align: center;
      font-weight: bold;
      font-size: 20px;
    }
  }
}
</style>
